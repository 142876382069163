import React from "react";
import "./Book.css";
import SI from "../../img/Couv.png";
import Mieux from "../../img/mieuxsaimer.jpg";
import Ami from "../../img/lami.jpg";
import Jeudi from "../../img/ajeudi.png";
import Emois from "../../img/emois-front.png";
import { Link } from "react-router-dom";
const Book = () => {
  return (
    <div className="book-container">
      <div>
        <h2>LIVRES</h2>
        <div className="flex-wrap">
          <div className="card">
            <Link to="/livres-de-victoire-theismann-emois">
              <h3 className="short-title">Émois</h3>
            </Link>
            <div className="line"></div>
            <Link to="/livres-de-victoire-theismann-emois">
              <img src={Emois} alt="" />
            </Link>
          </div>
          <div className="card">
            <Link to="/livres-de-victoire-theismann-si-je-change-le-monde-change">
              <h3>Si je change, le monde change</h3>
            </Link>
            <div className="line"></div>
            <Link to="/livres-de-victoire-theismann-si-je-change-le-monde-change">
              <img src={SI} alt="" />
            </Link>
          </div>
          <div className="card">
            <Link to="/livres-de-victoire-theismann-mieux-saimer-pour-aimer-mieux">
              <h3>Mieux s'aimer pour aimer mieux</h3>
            </Link>
            <div className="line"></div>
            <Link to="/livres-de-victoire-theismann-mieux-saimer-pour-aimer-mieux">
              <img src={Mieux} alt="" />
            </Link>
          </div>
          <div className="card">
            <Link to="/livres-de-victoire-theismann-l'-ami-eternel">
              <h3 className="short-title">L'ami d'éternité</h3>
            </Link>
            <div className="line"></div>
            <Link to="/livres-de-victoire-theismann-l'-ami-eternel">
              <img src={Ami} alt="" />
            </Link>
          </div>
          <div className="card">
            <Link to="/livres-de-victoire-theismann-a-jeudi">
              <h3 className="short-title">À jeudi</h3>
            </Link>
            <div className="line"></div>
            <Link to="/livres-de-victoire-theismann-a-jeudi">
              <img src={Jeudi} alt="" />
            </Link>
          </div>
        </div>
        <p>
          Lien librairie en ligne: &nbsp;
          <a
            href="https://www.zeroa.fr/832-victoire-theismann"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.zeroa.fr/832-victoire-theismann
          </a>
        </p>
      </div>
    </div>
  );
};

export default Book;
