import React from "react";
import { Link } from "react-router-dom";

export default ({ close }) => (
  <div className="menu">
    <ul>
      <li onClick={close}>
        <Link to="/"> ACCUEIL </Link>
      </li>
      <li onClick={close}>
        <Link to="/le-podcast-si-je-change-le-monde-change"> PODCAST </Link>
      </li>
      <li onClick={close}>
        <Link to="/les-episodes-du-si-je-change-le-monde-change">ÉPISODES</Link>
      </li>
      <li onClick={close}>
        <Link to="/livres-de-victoire-theismann">LIVRES</Link>
      </li>
      <Link to="/ils-en-parlents/interview-articles">
        <li onClick={close}>ILS EN PARLENT</li>
      </Link>

      <li onClick={close}>
        <Link to="/contactez-victoire-theismann">CONTACT </Link>
      </li>
    </ul>
  </div>
);
