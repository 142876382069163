import React from "react";
import "./Apropos.css";
import Victoire from "../../img/victoire-theismann.jpg";
import Vic from "../../img/victoire3.jpg";
import Vi from "../../img/vic2.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const Apropos = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Helmet>
        ‍<title>Victoire Theismann - A propos</title>
        <meta
          name="description"
          content="Victoire Theismann est une psychanalyste, psychothérapeute, autrice
            et metteuse en scène belge."
        />
      </Helmet>
      <div className="apropos-container">
        <br />
        <br />
        <h2>VICTOIRE THEISMANN</h2>
        <div className="slider-dis">
          <Slider className="carousel" {...settings} autoplay={true}>
            <div className="center">
              <img src={Victoire} alt="slider-image1" />
            </div>
            <div className="center">
              <img src={Vic} alt="slider-image2" />
            </div>
            <div className="center">
              <img src={Vi} alt="slider-image2" />
            </div>
          </Slider>
        </div>
        <br />
        <div className="apropos">
          <p>
            Victoire Theismann est psychanalyste, psycho praticienne, auteure,
            conférencière et metteure en scène. Elle est Belge et vit à Paris.
            Elle a été comédienne pendant 20 ans et a participé au doublage de
            nombreux films et téléfilms entre 1996 et 2004.
            <br />
            <br />
            Dans le même temps, à partir de 1996, elle reprend des études qui
            lui ouvrent les portes d’une nouvelle vie professionnelle. Formée à
            la psychothérapie psychanalytique et à la psychologie analytique
            Jungienne, elle s’intéresse aussi à la psychothérapie
            transgérationnelle, à l'analyse transactionnelle, à la sophrologie,
            au rêve éveillé, à EFT ainsi qu’à l’EMDR notamment auprès de Savoir
            Psy, d'Étienne Perrot, de l' EEPA (École européenne de philosophies
            et de psychothérapies appliquées). Elle y fut, par la suite,
            enseignante jusqu'en 2011. Elle s'est également formée à la
            SFU-Paris (Certification de la SFU-Vienne) et a participé dès 2007 à
            la création de la branche française SFU Paris (Centre universitaire
            privé de psychologie et psychothérapie appliquée et intégrative).
            Elle y a enseigné une année en 2009.
            <br />
            <br />
            En 2012, elle a assuré la mise en scène de la pièce « L'Amour dans
            tous ses états », écrite par Guy Corneau et Danielle Proulx et
            adaptée, pour l’Europe, par Camille Bardery. La pièce s'est jouée en
            2012 au forum du couple et de la famille à Montreux et au festival «
            Une autre façon d’Aimer » à Cabourg. En 2015, Victoire a retravaillé
            la mise en scène du spectacle dans une forme plus adaptée au
            théâtre. La pièce s’est ensuite jouée de 2015 à 2017 à Paris et en
            tournée en France, en Suisse, en Belgique et au Maroc.
            <br />
            <br />
            En tant qu’auteure, Victoire a écrit trois ouvrages : À jeudi
            (2006), L'Ami d'éternité (2016) et Si je change, le monde change
            (2021). Elle est également l'un des auteurs ayant participé au livre
            posthume de Guy Corneau: Mieux s'aimer pour aimer mieux. (2019). Les
            deux autres auteurs qui ont contribué à ce livre sont Thomas
            d'Ansembourg et Marie-Lise La Bonté.
            <br />
            <br />
            Elle a réalisé trois films documentaires entre 1998 et 2006.
            <br />
            <br />
            Elle donne des conférences inspirées par les sujets de ses livres en
            France et en Europe :
            <br />
            -Comment vivre le lien au-delà de la séparation physique
            <br />
            -Être le Poète de sa vie.
            <br />
            -Aimer commence par moi.
            <br />
            -Si je change, le monde change.
            <br />
            <br />
            En novembre 2020, elle a créé un podcast inspiré par son livre : Si
            je change, le monde Change paru chez Pygmalion en janvier 2021.
          </p>
          <h4>« La Vie n'a de sens que si c'est l'Amour qui guide »</h4>
          <br />
          <p className="author">- Victoire Theismann</p>
        </div>
      </div>
    </div>
  );
};

export default Apropos;
