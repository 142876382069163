import React, { useState, useEffect } from "react";
import "./Home.css";
import Papillon from "../../img/papillon.webp";
import { Helmet } from "react-helmet";
const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(false);
    };
  }, []);
  return (
    <div>
      <Helmet>
        ‍
        <title>
          Victoire Theismann - Si je change, le monde change. Podcasts
        </title>
        <meta
          name="description"
          content="Diffusé deux fois par mois et créé par Victoire Theismann, ce
          podcast est inspiré par son livre « Si je change, le monde change »
          sorti le 20 janvier 2021 aux Editions Pygmalion."
        />
      </Helmet>
      <div className="home-container">
        <br />
        <br />
        <h3>Si je change, le monde change: l’Effet Papillon</h3>
        <img src={Papillon} alt="" />
        <div className="lepodcast">
          <p>
            Le podcast qui traite d'écologie intérieure. En effet, si nous
            sommes alignés avec nos valeurs profondes, les actes que nous posons
            sont forcément en adéquation.
          </p>
          <br />
          <br />
          <p>
            Diffusé deux fois par mois et créé par Victoire Theismann, ce
            podcast est inspiré par son livre « Si je change, le monde change »
            sorti le 20 janvier 2021 aux Editions Pygmalion.
          </p>
          <br />

          <p>
            Victoire nous propose un rendez-vous bi-mensuel avec un.e invité.e.
            Une conversation informelle autour de l&#39;impact qu&#39;à notre
            humanité sur le Vivant et un partage des découvertes faites lors de
            ses recherches sur des sujets tels que l&#39;écologie,
            l&#39;écosophie, l&#39;environnement, le bien-être,
            l&#39;agriculture, la régénération et en lien avec le changement
            climatique.
          </p>
          <br />
          <p>
            Ce podcast est une invitation à découvrir comment chacun de nous
            peut poser des actes &quot;RESPONSABLES&quot; dans le sens anglo
            saxon du terme. Comment en faisant évoluer nos façons d&#39;être et
            de vivre, nous changeons nos vies et l&#39;impact que nous avons sur
            ce qui nous entoure, ceux qui nous entourent et le monde.
          </p>
          <br />
          <p>
            Ce podcast propose aussi de se questionner : Comment accéder au
            meilleur pour tous et pour la planète et comment découvrir, inventer
            des solutions qui offriront à nos enfants et aux générations futures
            la possibilité de retrouver le chemin du paradis sur terre que nous
            avons sacrifié sur l&#39;autel du consumérisme. Au fil des épisodes,
            nous découvrons que nous sommes de plus en plus nombreux à être
            conscients de notre capacité à créer une société plus inclusive,
            solidaire, respectueuse de tous et de notre planète.
          </p>
          <br />
          <p>Et... C'est tellement enthousiasmant !</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
