import React, { useState, useEffect } from "react";
import planete from "../../img/planete.jpg";
import Text from "../../img/textSJCLMC.png";
import "./Loading.css";

const Loading = () => {
  const [isPlaying, setPlaying] = useState(true);
  const [planetee, setPlanete] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setPlaying(false), 9000);
    setTimeout(() => setPlanete(true), 4000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  return isPlaying ? (
    <div>
      <div className="loading-container">
        <div id="pot">
          {!planetee && (
            <img src={planete} alt="" height="300px" width="300px" />
          )}
        </div>
        {planetee && (
          <div>
            <img
              src={planete}
              alt=""
              height="300px"
              width="300px"
              className="planetee"
            />
            <img className="textSI" src={Text} alt="" height="300px" />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="loading-container"></div>
  );
};

export default Loading;
