import React from "react";
import "./Footer.css";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Linked from "../../img/linked.png";
import Facebook from "../../img/facebook.png";
import Instagram from "../../img/instagram.png";
import Twitter from "../../img/twitter.png";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer">
          <div className="share">
            <a
              href="https://www.linkedin.com/in/victoire-theismann-a6998258/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinShareButton>
                <img src={Linked} alt="" />
              </LinkedinShareButton>
            </a>
            <a
              href="https://www.facebook.com/victoiretheismann.officiel"
              target="no_blank"
              rel="noreferrer"
            >
              <FacebookShareButton>
                <img src={Facebook} alt="" />
              </FacebookShareButton>
            </a>
            <a
              href="https://twitter.com/butterflyistb"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterShareButton>
                <img src={Twitter} alt="" />
              </TwitterShareButton>
            </a>
            <a
              href="https://www.instagram.com/victoire_theismann/?hl=fr"
              target="_blank"
              rel="noreferrer"
            >
              <InstapaperShareButton>
                <img src={Instagram} alt="" />
              </InstapaperShareButton>
            </a>
          </div>
          <p>© 2021 par Manon et Willy Merlet pour Victoire Theismann.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
