import React from "react";
import Lamis from "../../img/lami.jpg";
import Couv from "../../img/lamicouv.png";
const Lami = () => {
  return (
    <div>
      <br />
      <div className="sijechange-container">
        <h1>L'ami d'éternité</h1>
        <br />
        <div className="book-co">
          <div className="cardd">
            <div className="img-container">
              <img src={Lamis} alt="" />
            </div>
            <div className="cardd-details">
              <img src={Couv} alt="" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>
          Lien achat: &nbsp;
          <a
            href="https://livre.fnac.com/a9129456/Victoire-Theismann-L-ami-d-eternite#int=:NonApplicable|NonApplicable|NonApplicable|9129456|NonApplicable|NonApplicable"
            target="_blank"
            rel="noopener noreferrer"
          >
            L'ami d'éternité
          </a>
        </p>
      </div>
    </div>
  );
};

export default Lami;
