import React, { useEffect } from "react";
import "./Epsiodes.css";
import { Link } from "react-router-dom";
import { app } from "../../Utils/config";
import Spotify from "../../img/spot.png";
import Deezer from "../../img/deezer.png";
import Google from "../../img/google.png";
import Apple from "../../img/apple.png";
import Play from "../../img/download.svg";
import { Helmet } from "react-helmet";

const db = app.firestore();

const Episodes = () => {
  const [podcasts, setPodcasts] = React.useState([]);

  useEffect(() => {
    const fetchPodcasts = async () => {
      const podcastsCollection = await db
        .collection("Podcasts")
        .orderBy("dateAdded", "desc")
        .get();
      setPodcasts(
        podcastsCollection.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    fetchPodcasts();
  }, []);
  return (
    <div className="episode-container">
      <Helmet>
        <title>
          Victoire Theismann - Si je change, le monde change. Podcasts
        </title>
        <meta
          name="description"
          content="Diffusé deux fois par mois et créé par Victoire Theismann, ce
          podcast est inspiré par son livre « Si je change, le monde change »
          sorti le 20 janvier 2021 aux Editions Pygmalion."
        />
      </Helmet>
      <div className="episode">
        <h2>ECOUTEZ</h2>
        <h3>Si je change, le monde change: l'effet papillon</h3>
        <h4>Victoire Theismann</h4>
        <br />
        <div className="share podcast-logo">
          <a
            href="https://open.spotify.com/show/0pktGngeBVFggaFHTnmpc2"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Spotify} alt="" />
          </a>
          <a
            href="https://www.deezer.com/fr/show/1970382"
            target="no_blank"
            rel="noreferrer"
          >
            <img src={Deezer} alt="" />
          </a>
          <a
            href="https://twitter.com/butterflyistb"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Google} alt="" />
          </a>
          <a
            href="https://podcasts.apple.com/fr/podcast/si-je-change-le-monde-change-leffet-papillon/id1540073890"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Apple} alt="" />
          </a>
        </div>
        <p className="description-podcast">
          Les invité.e.s sont des artistes, des entrepreneurs, des auteurs, des
          thérapeutes, des chercheurs, des journalistes, etc. Victoire et ses
          invité.e.s nous invitent à nous interroger sur le pouvoir que nous
          avons, individuellement et collectivement, à faire évoluer nos états
          d&#39;être pour que nos actes soient en cohérence avec nos rêves
          d&#39;un monde meilleur pour tous. Et la réponse est : Oui !!!!! Cette
          possibilité est entre nos mains et les invité.e.s du podcast sont déjà
          sur ce chemin.
        </p>
        <br />
        <br />
        <br />
        <br />
        <section className="podcast">
          {podcasts.map((podcast, id) => {
            return (
              <Link to={`/podcast/${podcast.name}`}>
                <div className="podcast-box" key={id}>
                  <div className="itemsContainer">
                    <img className="play" src={Play} alt="" />
                    <img
                      className="image"
                      src={podcast.img}
                      alt={podcast.name}
                    />
                  </div>
                  <br />
                  <br />
                  <h5 className="h5-box">{podcast.title} </h5>
                  <span>
                    {podcast.length} | {podcast.date}
                  </span>
                  <p className="desc-box">{podcast.description}</p>
                </div>
              </Link>
            );
          })}
        </section>
      </div>
    </div>
  );
};

export default Episodes;
