import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import Menu from "../Menu";
import BurgerIcon from "../BurgerIcon";
const Header = () => {
  const contentStyle = {
    background: "rgba(255,255,255,0",
    width: "80%",
    border: "none",
  };
  return (
    <div>
      <header>
        <ul>
          <Link to="/">
            <li>ACCUEIL</li>
          </Link>
          <Link to="/le-podcast-si-je-change-le-monde-change">
            <li>PODCAST</li>
          </Link>
          <Link to="/les-episodes-du-si-je-change-le-monde-change">
            <li>ÉPISODES</li>
          </Link>
          <Link to="/livres-de-victoire-theismann">
            <li>LIVRES</li>
          </Link>
          <Link to="/ils-en-parlents/interview-articles">
            <li>ILS EN PARLENT</li>
          </Link>
          <Link to="/contactez-victoire-theismann">
            <li>CONTACT</li>
          </Link>
        </ul>
      </header>
      <Popup
        modal
        overlayStyle={{ background: "rgba(255,255,255,0.98" }}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={(open) => <BurgerIcon open={open} />}
      >
        {(close) => <Menu close={close} />}
      </Popup>
    </div>
  );
};

export default Header;
