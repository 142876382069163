import React from "react";
import Jeudi from "../../img/ajeudi.png";
import Couv from "../../img/couvjeudi.jpg";
const Ajeudi = () => {
  return (
    <div>
      <br />
      <div className="sijechange-container">
        <h1>À jeudi</h1>
        <br />
        <div className="book-co">
          <div className="cardd">
            <div className="img-container">
              <img src={Jeudi} alt="" />
            </div>
            <div className="cardd-details">
              <img src={Couv} alt="" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>
          Lien achat: &nbsp;
          <a
            href="https://livre.fnac.com/a5075117/Victoire-Theismann-A-jeudi#int=:NonApplicable%7CNonApplicable%7CNonApplicable%7C5075117%7CNonApplicable%7CNonApplicable"
            target="_blank"
            rel="noopener noreferrer"
          >
            À jeudi
          </a>
        </p>
      </div>
    </div>
  );
};

export default Ajeudi;
