import React from "react";
import "./IlsEnParlent.css";
import Alsace from "../../img/alsace.jpeg";

const IlsEnParlent = () => {
  return (
    <div className="article-container">
      <div className="enparler">
        <h4>ILS EN PARLENT</h4>
        <div className="card-parler-wrapper">
          <a
            href="https://radiorcj.info/diffusions/victoire-theismann-est-linvitee-de-marc-welinski-si-je-change-le-monde-change/?fbclid=IwAR30qpfg4KnjkA8x_DrGl8a4FFzimWv5cQVI3EDp6OtFwHdTGbdw6Bhzb-c"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>23 Janvier 2022</span>
              </div>
              <br />
              <h5>
                Victoire Theismann est l’invitée de Marc Welinski – Si je
                change, le monde change - RCJ
              </h5>
            </div>
          </a>
          <a
            href="https://podcast.ausha.co/tropcalia/victoire-theismann-repenser-la-consommation"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>18 Juin 2021</span>
              </div>
              <br />
              <h5>Tropcalia, Victoire Theismann, Repenser la consommation</h5>
            </div>
          </a>
          <a
            href="https://culturepapier.org/si-je-change-le-monde-change-aussi-le-plaidoyer-pour-un-art-de-vivre-et-de-vivre-ensemble-de-victoire-theismann/?fbclid=IwAR1Oo1LMHH-nzA4qgwL0UqSQEJkFgtjk1GFlrp7LbdhEwypGNveCrbYFbEY"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>17 Mai 2021</span>
              </div>
              <br />
              <h5>
                Article culture papier : "Si je change, le monde change aussi"
              </h5>
            </div>
          </a>
          <a
            href="https://www.phenixweb.info/Si-je-change-le-monde-change"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>4 avril 2021</span>
              </div>
              <br />
              <h5>PhenixWeb : Si je change, le monde change.</h5>
            </div>
          </a>
          <a href={Alsace} target="_blank" rel="noreferrer">
            <div className="parler-card">
              <div>
                <br />
                <span>2 Avril 2021</span>
              </div>
              <br />
              <h5>Article journal l'Alsace : "Plaidoyer pour le vivant"</h5>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=relSNGCNrqE"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>1 Avril 2021</span>
              </div>
              <br />
              <h5>
                Interview : "Je change, le monde change - Conversation entre
                Pierre Portevin et Victoire Theismann" de Daredo
              </h5>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=NKIw-IYRH0k"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>31 Mars 2021</span>
              </div>
              <br />
              <h5>
                Interview : "Victoire Theismann, Si je change, le monde change"
                de Didelot Sylvain Terr2 TV
              </h5>
            </div>
          </a>
          <a
            href="https://www.facebook.com/victoire.theismann/videos/10159223382098119"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>28 Mars 2021</span>
              </div>
              <br />
              <h5>
                Avis livre : "Si je change, le monde change" de Aysegul Sert
                (journaliste Americano-Turc)
              </h5>
            </div>
          </a>
          <a
            href="https://www.lesuricate.org/si-je-change-le-monde-change-un-ouvrage-resolument-optimiste/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>27 mars 2021</span>
              </div>
              <br />
              <h5>
                Article lesuricate : "Si je change, le monde change », un
                ouvrage résolument optimiste"
              </h5>
            </div>
          </a>
          <a
            href="https://www.facebook.com/victoire.theismann/videos/10159217111408119"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>25 Mars 2021</span>
              </div>
              <br />
              <h5>
                Avis livre : "Si je change, le monde change" de Sandrine
                Hasselwander (lectrice)
              </h5>
            </div>
          </a>
          <a
            href="https://entredeuxpagesfr.wordpress.com/2021/03/24/si-je-change-le-monde-change-victoire-theismann/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>24 Mars 2021</span>
              </div>
              <br />
              <h5>
                Chronique de ENTRE DEUX PAGES sur : "Si je change, le monde
                change."
              </h5>
            </div>
          </a>
          <a target="_blank" rel="noreferrer">
            <div className="parler-card">
              <div>
                <br />
                <span>12 Mars 2021</span>
              </div>
              <br />
              <h5>RCF Bruxelles. Interview de Delphine Freyssinet</h5>
            </div>
          </a>
          <a
            href="https://www.facebook.com/BIOTEMPO.BIO/photos/a.534969863318216/1828415417306981"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>24 Février 2021</span>
              </div>
              <br />
              <h5>
                Biotempo magazine, article: "En tête-à-tête avec Victoire
                Theismann"
              </h5>
            </div>
          </a>
          <a
            href="https://www.instagram.com/tv/CKuGzz3houD/?igshid=1exmf87u3h1do"
            target="_blank"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>30 Janvier 2021</span>
              </div>
              <br />
              <h5>Instalife avec Odile Chabrillac </h5>
            </div>
          </a>
          <a
            target="_blank"
            href="https://www.rts.ch/play/radio/egosysteme/audio/la-force-verte?id=11882935"
            rel="noreferrer"
          >
            <div className="parler-card">
              <div>
                <br />
                <span>23 Janvier 2021</span>
              </div>
              <br />
              <h5>RTS EGOSYSTEME. Interview de Florence Farion </h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default IlsEnParlent;
