import React from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_l5kgww6",
        "template_3z9frop",
        e.target,
        "user_fMCvX9xnDzQE2z7trDFbr"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <div className="row formm">
        <form onSubmit={sendEmail}>
          <h3 className="titre-cont">CONTACT</h3>
          <h2 className="souhaite">
            Vous souhaitez échanger ? <br /> Programmer une conférence ? <br />
            Me proposer un.e invité.e ? <br /> Je suis à votre écoute...
          </h2>

          <div className="column">
            <div className="row">
              <input
                className="name"
                type="text"
                placeholder="Prenom *"
                name="name"
              />
              <input
                className="nom"
                type="text"
                placeholder="Nom *"
                name="nom"
              />
            </div>
            <div className="row">
              <input
                className="email"
                type="email"
                placeholder="Email *"
                name="email"
              />
              <input
                className="tel"
                type="text"
                placeholder="Telephone"
                name="tel"
              />
            </div>
            <textarea
              cols="10"
              rows="10"
              placeholder="Votre message *"
              name="subject"
            ></textarea>
            <div className="envoyer">
              <button className="envoy" type="submit">
                ENVOYER
              </button>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
