import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./containers/Home/Home";
import Apropos from "./containers/A Propos/Apropos";
import Contact from "./containers/Contact/Contact";
import Book from "./containers/Book/Book";
import Sijechange from "./components/Sijechange/Sijechange";
import Mieux from "./components/Mieux/Mieux";
import Jeudi from "./components/Ajeudi/Ajeudi";
import Lami from "./components/Lami/Lami";
import Episodes from "./containers/Episodes/Episodes";
import IlsEnParlent from "./containers/IlsEnParlent/IlsEnParlent";
import Emois from "./components/Emois/Emois";
import ScrollToTop from "./ScrollToTop";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlayCircle, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import EpisodeDetail from "./containers/Episode/EpisodeDetailView";
import Loading from "./containers/Loading/Loading";

library.add(faPlayCircle, faCaretRight);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path="/livres-de-victoire-theismann-emois">
          <Emois />
        </Route>
        <Route path="/loading">
          <Loading />
        </Route>
        <Route path="/podcast/:id">
          <EpisodeDetail />
        </Route>
        <Route path="/ils-en-parlents/interview-articles">
          <IlsEnParlent />
        </Route>
        <Route path="/les-episodes-du-si-je-change-le-monde-change">
          <Episodes />
        </Route>
        <Route path="/livres-de-victoire-theismann-l'-ami-eternel">
          <Lami />
        </Route>
        <Route path="/livres-de-victoire-theismann-a-jeudi">
          <Jeudi />
        </Route>
        <Route path="/livres-de-victoire-theismann-mieux-saimer-pour-aimer-mieux">
          <Mieux />
        </Route>
        <Route path="/livres-de-victoire-theismann-si-je-change-le-monde-change">
          <Sijechange />
        </Route>
        <Route path="/livres-de-victoire-theismann">
          <Book />
        </Route>
        <Route path="/contactez-victoire-theismann">
          <Contact />
        </Route>

        <Route path="/le-podcast-si-je-change-le-monde-change">
          <Home />
        </Route>
        <Route path="/">
          <Apropos />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
