import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyDA_x9di9oxzSwl1_cxqUs7r51QHy3SJHw",
  authDomain: "sjclmc-710b2.firebaseapp.com",
  projectId: "sjclmc-710b2",
  storageBucket: "sjclmc-710b2.appspot.com",
  messagingSenderId: "971888529184",
  appId: "1:971888529184:web:863dd04d27ef1b59e8d145",
  measurementId: "G-9GVDP7R1RT",
});
