import React, { useEffect } from "react";
import "./episode.css";
import { useParams } from "react-router-dom";
import { app } from "../../Utils/config";
import { Link } from "react-router-dom";
import Spotify from "../../img/spot.png";
import Soundcloud from "../../img/deezer.png";
import Google from "../../img/google.png";
import Apple from "../../img/apple.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Helmet } from "react-helmet";

const db = app.firestore();

const EpisodeDetailView = () => {
  const [podcasts, setPodcasts] = React.useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchPodcasts = async () => {
      const podcastsCollection = db.collection("Podcasts").doc(id);
      const doc = await podcastsCollection.get();
      if (!doc.exists) {
        console.log("No doc");
      } else {
        setPodcasts(doc.data());
        console.log("Document data", doc.data());
      }
    };
    fetchPodcasts();
  }, []);

  return (
    <div>
      <div className="episode-continer">
        <br />
        <h2>PODCAST</h2>
        <section>
          <div className="player-container">
            <br />
            <Link to="/les-episodes-du-si-je-change-le-monde-change">
              <p>
                <FontAwesomeIcon icon="caret-right" /> Tous les épisodes
              </p>
            </Link>
            <br />
            <div className="roww">
              <img className="perso-im" src={podcasts.img} alt="" />
              <div className="col">
                <h3>Victoire Theismann</h3>
                <h2>{podcasts.title}</h2>
                <div className=" episode-im">
                  <a
                    href="https://open.spotify.com/show/0pktGngeBVFggaFHTnmpc2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Spotify} alt="" />
                  </a>
                  <a
                    href="https://www.deezer.com/fr/show/1970382"
                    target="no_blank"
                    rel="noreferrer"
                  >
                    <img src={Soundcloud} alt="" />
                  </a>
                  <a
                    href="https://twitter.com/butterflyistb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Google} alt="" />
                  </a>
                  <a
                    href="https://podcasts.apple.com/fr/podcast/si-je-change-le-monde-change-leffet-papillon/id1540073890"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Apple} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="audio">
              <AudioPlayer src={podcasts.audio} />
            </div>
          </div>
        </section>
        <div className="desc-container">
          <h5>Victoire theismann | {podcasts.date} </h5>
          <p>{podcasts.description}</p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default EpisodeDetailView;
