import React from "react";
import Couv from "../../img/couvsi.png";
import SI from "../../img/Couv.png";
import "./Sijechange.css";
const Sijechange = () => {
  return (
    <div>
      <br />
      <div className="sijechange-container">
        <h1>Si je change, le monde change</h1>
        <br />
        <div className="book-co">
          <div className="cardd">
            <div className="img-container">
              <img src={SI} alt="" />
            </div>
            <div className="cardd-details">
              <img src={Couv} alt="" />
            </div>
          </div>
        </div>

        <br />
        <br />
        <p>
          Lien achat: &nbsp;
          <a
            href="https://livre.fnac.com/a14051121/Victoire-Theismann-Si-je-change-le-monde-change#int=:NonApplicable%7CNonApplicable%7CNonApplicable%7C14051121%7CNonApplicable%7CNonApplicable"
            target="_blank"
            rel="noopener noreferrer"
          >
            Si je change, le monde change
          </a>
        </p>
      </div>
    </div>
  );
};

export default Sijechange;
