import React from "react";
import "./Mieux.css";
import Mieuxx from "../../img/mieuxsaimer.jpg";
import M from "../../img/ajeudi.png";
const Mieux = () => {
  return (
    <div>
      <br />
      <div className="sijechange-container">
        <h1>Mieux s'aimer pour aimer mieux</h1>
        <br />
        <div className="book-co">
          <div className="cardd">
            <div className="img-container">
              <img src={Mieuxx} alt="" />
            </div>
            <div className="cardd-details">
              <div className="img-container">
                <img src={M} alt="" />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>
          Lien achat: &nbsp;
          <a
            href="https://livre.fnac.com/a13043916/Guy-Corneau-Mieux-s-aimer-pour-aimer-mieux?oref=00000000-0000-0000-0000-000000000000&storecode=4009&Origin=SEA_GOOGLE_PLA_BOOKS&esl-k=sem-google%7cng%7cc258157034827%7cm%7ckpla297361043185%7cp%7ct%7cdc%7ca60051878344%7cg1266443476&gclid=CjwKCAjw6qqDBhB-EiwACBs6x8x8Zl0Y6kB9j6XTWX7b4EN55QWh6_OvsXE3dAVFd26AiY1KFQilwxoCdgoQAvD_BwE&gclsrc=aw.ds"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mieux s'aimer pour aimer mieux
          </a>
        </p>
      </div>
    </div>
  );
};

export default Mieux;
