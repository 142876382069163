import React from "react";
import Jeudi from "../../img/emois-front.png";
import Couv from "../../img/emois-back.png";
const Emois = () => {
  return (
    <div>
      <br />
      <div className="sijechange-container">
        <h1>Émois</h1>
        <br />
        <div className="book-co">
          <div className="cardd">
            <div className="img-container">
              <img src={Jeudi} alt="" />
            </div>
            <div className="cardd-details">
              <img src={Couv} alt="" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>
          Lien achat: &nbsp;
          <a
            href="https://www.amazon.fr/%C3%89mois-victoire-Theismann/dp/B09MYRFZFG"
            target="_blank"
            rel="noopener noreferrer"
          >
            Émois
          </a>
        </p>
      </div>
    </div>
  );
};

export default Emois;
